import type { APIKey, Organization, Page, Project, User } from "./types";

export async function getAPI(url: string): Promise<any> {
  return fetch(url).then((res) => {
    if (res.status === 200) return res.json();
    else throw new Error(`Unexpected response status ${res.status}`);
  });
}

export async function postAPI(url: string, body: any): Promise<any> {
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }).then((res) => {
    if (res.status === 200) return res.json();
    else throw new Error(`Unexpected response status ${res.status}`);
  });
}

export async function fetchOrganization(): Promise<Organization> {
  return getAPI("/api/organization");
}

export async function fetchProjects(): Promise<Page<Project>> {
  return getAPI("/api/projects");
}

export async function fetchUsers(): Promise<Page<User>> {
  return getAPI("/api/users");
}

export async function fetchAPIKeys(): Promise<Page<APIKey>> {
  return getAPI("/api/api-keys");
}
