import { useEffect, useState } from "react";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { Navbar, Row } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";

import type { CurrentSession, NeedsLogin } from "../types";
import Auth from "../Auth";
import AvatarPopover from "./AvatarPopover";
import Sidebar from "./Sidebar";

function Header({
  whoami,
  onLogOut,
}: {
  whoami: CurrentSession;
  onLogOut: () => void;
}) {
  return (
    <Navbar
      sticky="top"
      className="bg-body-tertiary shadow px-3 mb-2"
      id="header"
    >
      <Navbar.Brand>
        <Link
          to="/"
          className="link-underline link-underline-opacity-0 text-dark"
        >
          Artificial.Agency / {whoami.organization.name}
        </Link>
      </Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
        <AvatarPopover whoami={whoami} onLogOut={onLogOut} />
      </Navbar.Collapse>
    </Navbar>
  );
}

function Layout() {
  const [whoami, setWhoami] = useState<CurrentSession | null>(null);
  const [needsLogin, setNeedsLogin] = useState<NeedsLogin | null>(null);

  const fetchCurrentSession = () => {
    fetch("/api/auth/whoami")
      .then((res) => {
        if (res.status === 200)
          res.json().then((whoami: CurrentSession) => setWhoami(whoami));
        else if (res.status === 401)
          res
            .json()
            .then((needsLogin: NeedsLogin) => setNeedsLogin(needsLogin));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchCurrentSession();
  }, []);

  const logOut = () => {
    fetch("/api/auth/log-out", { method: "POST" })
      .then(() => {
        setWhoami(null);
        fetchCurrentSession();
      })
      .catch((err) => console.log(err));
  };

  if (!whoami) {
    if (!needsLogin) {
      return (
        <div id="layout-loading">
          <Spinner />
        </div>
      );
    } else if (!whoami) {
      return (
        <GoogleOAuthProvider clientId={needsLogin.client_id}>
          <Container id="layout-login">
            <h2>Sign In</h2>

            <Auth onSuccess={(whoami) => setWhoami(whoami)} />
          </Container>
        </GoogleOAuthProvider>
      );
    }
  } else {
    return (
      <>
        <Header whoami={whoami} onLogOut={logOut} />
        <Container fluid>
          <Row>
            <Sidebar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 pt-2">
              <Outlet />
            </main>
          </Row>
        </Container>
      </>
    );
  }
}

export default Layout;
