import { useEffect, useState } from "react";

import { Alert, Button, Form, Spinner, Table } from "react-bootstrap";

import type { APIKey, Page } from "../api/types";
import { fetchAPIKeys, postAPI } from "../api";

type NewAPIKey = {
  notes: string;
};

function NewAPIKeyRow({
  onAddedAPIKey,
}: {
  onAddedAPIKey: (u: APIKey) => void;
}) {
  const [notes, setNotes] = useState<string>("");
  const [submitting, setSubmitting] = useState(false);

  const createAPIKey = (newAPIKey: NewAPIKey) => {
    setSubmitting(true);
    postAPI(`/api/api-keys`, newAPIKey)
      .then((u: APIKey) => {
        onAddedAPIKey(u);
        setNotes("");
      })
      .catch((err) => console.log(err))
      .finally(() => setSubmitting(false));
  };

  return (
    <tr>
      <td />
      <td>
        <Form.Control
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="notes"
        />
      </td>

      <td>
        <Button
          variant="light"
          onClick={() => createAPIKey({ notes })}
          disabled={submitting || !notes}
        >
          {submitting ? <Spinner size="sm" /> : "➕"}
        </Button>
      </td>
    </tr>
  );
}

export type RevealedSecretKey = {
  id: string;
  secret_key: string;
};

function RevealableAPIKey({ apiKey }: { apiKey: APIKey }) {
  const [revealedSecretKey, setRevealedSecretKey] = useState<string | null>(
    apiKey.secret_key || null,
  );
  const [submitting, setSubmitting] = useState(false);

  const revealSecretKey = (apiKey: APIKey) => {
    setSubmitting(true);
    postAPI(`/api/api-keys/${apiKey.id}/reveal`, {})
      .then((k: RevealedSecretKey) => {
        setSubmitting(false);
        setRevealedSecretKey(k.secret_key);
      })
      .catch((err) => console.log(err));
  };

  if (revealedSecretKey) return <code>{revealedSecretKey}</code>;

  return (
    <>
      <code>{apiKey.redacted_secret_key}</code>{" "}
      <span className="float-end">
        {submitting ? (
          <Spinner size="sm" />
        ) : (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => revealSecretKey(apiKey)}
            className="bi-eye-fill"
          />
        )}
      </span>
    </>
  );
}

function APIKeysPage() {
  const [loading, setLoading] = useState(false);
  const [apiKeys, setAPIKeys] = useState<Page<APIKey> | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);

    fetchAPIKeys()
      .then((us) => {
        setAPIKeys(us);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  const addedAPIKey = (u: APIKey) => {
    setAPIKeys({
      ...apiKeys!,
      items: [...apiKeys!.items, u],
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  } else if (!apiKeys) {
    return (
      <Alert variant="warning">
        Could not load API keys: {error?.message}.
      </Alert>
    );
  } else {
    return (
      <>
        <h2>API keys</h2>

        <p>
          API keys are credentials that allow your client software to access the
          Artificial.Agency API.
        </p>

        <Table>
          <thead>
            <tr>
              <th>API Key</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {apiKeys.items.map((k) => {
              return (
                <tr key={k.id}>
                  <td>
                    <RevealableAPIKey apiKey={k} />
                  </td>
                  <td>{k.notes}</td>
                </tr>
              );
            })}
            <NewAPIKeyRow onAddedAPIKey={(u) => addedAPIKey(u)} />
          </tbody>
        </Table>
      </>
    );
  }
}

export default APIKeysPage;
