import { useEffect, useState } from "react";

import { Alert, Button, Form, Spinner, Table } from "react-bootstrap";

import type { Page, Project } from "../api/types";
import { fetchProjects, postAPI } from "../api";

type NewProject = {
  name: string;
};

function NewProjectRow({
  onAddedProject,
}: {
  onAddedProject: (p: Project) => void;
}) {
  const [name, setName] = useState<string>("");
  const [submitting, setSubmitting] = useState(false);

  const createProject = (newProject: NewProject) => {
    setSubmitting(true);
    postAPI(`/api/projects`, newProject)
      .then((p: Project) => {
        onAddedProject(p);
        setName("");
      })
      .catch((err) => console.log(err))
      .finally(() => setSubmitting(false));
  };

  return (
    <tr>
      <td />
      <td>
        <Form.Control
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="name"
        />
      </td>

      <td>
        <Button
          variant="light"
          onClick={() => createProject({ name })}
          disabled={submitting || !name}
        >
          {submitting ? <Spinner size="sm" /> : "➕"}
        </Button>
      </td>
    </tr>
  );
}

function ProjectsPage() {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState<Page<Project> | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);

    fetchProjects()
      .then((ps) => {
        setProjects(ps);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  const addedProject = (p: Project) => {
    setProjects({
      ...projects!,
      items: [...projects!.items, p],
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  } else if (!projects) {
    return (
      <Alert variant="warning">
        Could not load projects: {error?.message}.
      </Alert>
    );
  } else {
    return (
      <>
        <h2>Projects</h2>

        <p>
          Projects allow you to organize your account to make it easier to find
          & attribute activity.
        </p>

        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {projects.items.map((p) => {
              return (
                <tr key={p.id}>
                  <td>
                    <code>{p.id}</code>
                  </td>
                  <td>{p.name}</td>
                </tr>
              );
            })}
            <NewProjectRow onAddedProject={(p) => addedProject(p)} />
          </tbody>
        </Table>
      </>
    );
  }
}

export default ProjectsPage;
