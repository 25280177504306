import { useEffect, useState } from "react";

import { Alert, Table } from "react-bootstrap";

import type { Organization } from "../api/types";
import { fetchOrganization } from "../api";

function OrganizationPage() {
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);

    fetchOrganization()
      .then((org) => {
        setOrganization(org);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  } else if (!organization) {
    return (
      <Alert variant="warning">
        Could not load organization: {error?.message}.
      </Alert>
    );
  } else {
    return (
      <Table>
        <tbody>
          <tr>
            <th>Short Name</th>
            <td>{organization.short_name}</td>
          </tr>
          <tr>
            <th>Name</th>
            <td>{organization.name}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default OrganizationPage;
