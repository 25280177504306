import { useEffect, useState } from "react";

import { Alert, Button, Form, Spinner, Table } from "react-bootstrap";

import type { Page, User } from "../api/types";
import { fetchUsers, postAPI } from "../api";

type NewUser = {
  email: string;
};

function NewUserRow({ onAddedUser }: { onAddedUser: (u: User) => void }) {
  const [email, setEmail] = useState<string>("");
  const [submitting, setSubmitting] = useState(false);

  const createUser = (newUser: NewUser) => {
    setSubmitting(true);
    postAPI(`/api/users`, newUser)
      .then((u: User) => {
        onAddedUser(u);
        setEmail("");
      })
      .catch((err) => console.log(err))
      .finally(() => setSubmitting(false));
  };

  return (
    <tr>
      <td>
        <Form.Control
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="email"
        />
      </td>

      <td>
        <Button
          variant="light"
          onClick={() => createUser({ email })}
          disabled={submitting || !email}
        >
          {submitting ? <Spinner size="sm" /> : "➕"}
        </Button>
      </td>
    </tr>
  );
}

function UsersPage() {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<Page<User> | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);

    fetchUsers()
      .then((us) => {
        setUsers(us);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  const addedUser = (u: User) => {
    setUsers({
      ...users!,
      items: [...users!.items, u],
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  } else if (!users) {
    return (
      <Alert variant="warning">Could not load users: {error?.message}.</Alert>
    );
  } else {
    return (
      <>
        <h2>Users</h2>

        <p>
          Users are accounts that are allowed to access this organization's
          dashboard.
        </p>

        <Table>
          <thead>
            <tr>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {users.items.map((u) => {
              return (
                <tr key={u.id}>
                  <td>{u.email}</td>
                </tr>
              );
            })}
            <NewUserRow onAddedUser={(u) => addedUser(u)} />
          </tbody>
        </Table>
      </>
    );
  }
}

export default UsersPage;
