import { createBrowserRouter } from "react-router-dom";

import APIKeysPage from "./api-keys/APIKeysPage";
import DashboardIndex from "./DashboardIndex";
import ErrorPage from "./ErrorPage";
import Layout from "./layout/";
import OrganizationPage from "./organization/OrganizationPage";
import ProjectsPage from "./projects/ProjectsPage";
import UsersPage from "./users/UsersPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <DashboardIndex />,
      },
      {
        path: "/organization",
        element: <OrganizationPage />,
      },
      {
        path: "/projects",
        element: <ProjectsPage />,
      },
      {
        path: "/users",
        element: <UsersPage />,
      },
      {
        path: "/api-keys",
        element: <APIKeysPage />,
      },
    ],
  },
]);

export default router;
